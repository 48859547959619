import React from "react"
import Contacts from "../components/Contacts"
import Footer from "../components/Footer"
import Head from "../components/Head"

// Image
import EmailGIF from "../static/email.gif"

class Kontakt extends React.Component {
  render() {
    return (
      <div>
        <Head />
        <div class="container">
          <div class="main">
            <div class="header">
              <div class="title">
                {" "}
                <a href="/">
                  <h1> </h1>
                </a>{" "}
              </div>
            </div>
            <div class="content">
              <div class="item">
                <h1>Kontaktdaten</h1>
                <br />
                <p class="MsoNormal">
                  {" "}
                  <span>
                    Katharina Gabber
                    <br />
                    Stangeracker 8<br />
                    85132 Workerszell
                    <br />
                    Tel.: 08421 / 80924
                  </span>
                  <br />
                  <span>
                    {" "}
                    <br />
                    E-Mail: <img src={EmailGIF} align="absbottom" alt="" />
                  </span>
                </p>
              </div>
              <div class="item">
                <h1>Impressum</h1>
                <br />
                <p class="MsoNormal">
                  <b>
                    {" "}
                    <span>Inhaltliche Verantwortung</span>
                  </b>
                </p>
                <p>
                  <span>
                    {" "}
                    Katharina&nbsp; Gabber
                    <br />
                    Stangeracker 8<br />
                    85132 Workerszell
                    <br />
                    Tel.: 08421 / 80924
                    <br />
                  </span>{" "}
                  <span>
                    <br />
                    E-Mail: <img src={EmailGIF} align="absbottom" alt="" />
                  </span>
                </p>
                <p>
                  <span>
                    <b> Haftung für Links</b>
                  </span>
                </p>
                <p>
                  <span>
                    {" "}
                    Hiermit distanzieren sich, die im Impressum genannten,
                    ausdrücklich von allen Inhalten verlinkter Seiten oder
                    Grafiken und machen sich diese keinesfalls zu eigen.
                    Sämtliche Verstöße gegen geltendes Recht, Sitte oder Moral,
                    welche bekannt werden, haben die sofortige Löschung von
                    Links, Beiträgen, Einträgen, Grafiken oder ähnlichem zur
                    Folge.
                  </span>
                  <br />
                </p>
              </div>
            </div>
            <Contacts />
            <div class="clearer">
              <span />
            </div>
          </div>
          <Footer />
        </div>
      </div>
    )
  }
}

export default Kontakt
